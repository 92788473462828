import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "gatsby";
import profile from "../images/profile.png";

export default class Header extends React.Component {
    render() {
        return(
            <header className="blog-header">
                <Link className="blog-profile" to="/">
                    <h2>{this.props.title}</h2>
                    <h6>{this.props.description}</h6>
                </Link>
                <Image className="blog-profile-picture" src={profile}/>
            </header>
        )
    }
}