import React from "react";
import { Container } from "react-bootstrap";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ children }) {
    const { title, description, url, copyright } = useSiteMetadata();
    return(
        <Container className="blog">
            <Header title={title} description={description}/>
            <article className="blog-article">
                {children}
            </article>
            <Footer url={url} copyright={copyright}/>
        </Container>
    )
}