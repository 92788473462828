import React from "react";
import { Image } from "react-bootstrap";
import nc_logo from "../images/nc-logo.svg";

export default class Footer extends React.Component {
    render() {
        return(
            <footer className="blog-footer">
                <p className="copyright">{`\u00A9 ${this.props.copyright}`}</p>
                <a className="nc-logo" href={this.props.url}>
                    <Image src={nc_logo}/>
                </a>
            </footer>
        )
    }
}